<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="productCollection"
          :search="search"
          :footer-props="{
            'items-per-page-options': [50, 75, 100],
          }"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <productModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                :publicationCollection="publicationCollection"
                :priceListCollection="priceListCollection"
                :productCollection="productCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></productModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.status="{ item }">
            {{ getStatus(item.status) }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ new Date(item.created_at).toLocaleString("hu-HU", {
                timeZone: "UTC",
              }) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import ProductModalForm, { initialFormData } from "./ProductModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "products.property.";

export default {
  name: "Products",
  components: { ProductModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.PRODUCTS"),
      search: "",
      routePath: "/products/products/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",          
        },
        {
          text: this.$t("TABLE.categories"),
          value: "productCategoryName",          
        },
        
        { text: this.$t("TABLE.status"), value: "status" },
        { text: this.$t("TABLE.created_at"), value: "created_at" },
        
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([
      "productCollection",
      "priceListCollection",
      "publicationCollection",
      "productCategoryCollection",
      "getProductCategoryByID",
      "statuses",
    ]),

    endPoint() {
      return "products/admin/" + SiteService.getActiveSiteId() + "/product/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchProduct", "fetchPriceList", "fetchPublication", "fetchProductCategory"]),
    fetchModel() {
      this.fetchPriceList();
      this.fetchPublication();
      this.fetchProductCategory();
      
      return this.fetchProduct().then(() => 
      {
        this.productCollection.map(item => 
          {
            item.productCategoryName = this.getProductCategoryByID(item.product_category_id).name
            return item
          })
        this.loadingTable = false
      }
    
    );
    },

    getStatus(val) {
      if (this.statuses.webshop && this.statuses.products.product) {
        return this.$t(
          "STATUSES." +
            this.statuses.webshop.coupon.find(
              (item) => item.key == val || (!item.key && !val)
            ).value
        );
      }
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel()
  },
};
</script>
