<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    :retain-focus="false"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.PRODUCTS") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.products"
                v-model="formModel.status"
                :items="statuses.products.product"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="formModel.custom_fields.isPackage"
                :items="packageCollection"
                :label="$t('FORM_INPUT_NAMES.isPackage')"
                item-text="name"
                item-value="id"
                :rules="packageRules"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="formModel.product_category_id"
                :items="productCategoryCollection"
                :label="$t('FORM_INPUT_NAMES.category')"
                item-text="name"
                item-value="id"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :label="$t('FORMS.name')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
              <v-text-field
                v-if="
                  formModel.id && formModel.translations[selectedLocale.lang]
                "
                v-model="formModel.translations[selectedLocale.lang].slug"
                :label="$t('FORMS.slug')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.slug']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.slug'] = ''
                "
              ></v-text-field>
              <v-row class="mb-7">
                <v-col cols="12" sm="6" md="6">
                  <!-- :rules="nameRules" -->
                  <v-text-field
                    v-if="formModel"
                    v-model="formModel.ean"
                    label="EAN"
                    :id="dynamicID"
                    :error-messages="messages['ean']"
                    @keyup="messages['ean'] = ''"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- :rules="nameRules" -->
                  <v-text-field
                    v-if="formModel"
                    v-model="formModel.sku"
                    label="SKU"
                    :id="dynamicID"
                    :error-messages="messages['sku']"
                    @keyup="messages['sku'] = ''"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    v-model="formModel.enable_gmc"
                    :label="$t('FORM_INPUT_NAMES.enable_gmc')"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="
                  formModel.translations[selectedLocale.lang].description
                "
                :error-messages="
                  messages[
                    'translations.' + selectedLocale.lang + '.description'
                  ]
                "
                @input="
                  messages[
                    'translations.' + selectedLocale.lang + '.description'
                  ] = ''
                "
                :config="editorConfig"
              ></ckeditor>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="formModel.is_gift"
                    :label="$t('FORM_INPUT_NAMES.isGift')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="formModel.is_gift">
                <v-col cols="12" sm="8" md="8">
                  <CustomFieldComponent
                    customFieldName="validity_date"
                    :value="formModel.gift_date || []"
                    :config="{
                      type: 'date_range_picker',
                    }"
                    @handleChangeCustomFieldValue="
                      handleChangeFormModelCustomFieldValue
                    "
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-if="formModel.is_gift"
                    v-model="formModel.gift_from_price"
                    label="Összeghatár"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-if="formModel.price_lists[0]"
                    v-model="formModel.price_lists[0].price_net"
                    :label="$t('FORM_INPUT_NAMES.total_net')"
                    type="number"
                    min="0"
                    v-on:input="handleCalculateBrutto"
                  ></v-text-field>
                  <v-text-field
                    v-if="formModel.price_lists[1]"
                    v-model="formModel.price_lists[1].price_net"
                    :label="$t('FORM_INPUT_NAMES.total_net_special')"
                    type="number"
                    min="0"
                    v-on:input="handleCalculateSpecialBrutto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-if="formModel.price_lists[0]"
                    v-model="formModel.price_lists[0].price_gross"
                    :label="$t('FORM_INPUT_NAMES.total_gross')"
                    type="number"
                    min="0"
                    v-on:input="handleCalculateNetto"
                  ></v-text-field>
                  <v-text-field
                    v-if="formModel.price_lists[1]"
                    v-model="formModel.price_lists[1].price_gross"
                    :label="$t('FORM_INPUT_NAMES.total_gross_special')"
                    type="number"
                    min="0"
                    v-on:input="handleCalculateSpecialNetto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  formModel &&
                  formModel.custom_fields &&
                  formModel.custom_fields.isPackage
                "
              >
                <v-col cols="10">
                  <v-autocomplete
                    v-model="selectedProduct"
                    :items="productCollection"
                    label="Válassz termékeket a csomaghoz"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" class="mt-5">
                  <v-icon
                    color="primary"
                    v-if="selectedProduct"
                    small
                    style="cursor: pointer"
                    @click="
                      formModel.custom_fields.productIds = [
                        ...formModel.custom_fields.productIds,
                        selectedProduct,
                      ]
                    "
                  >
                    mdi-plus-circle
                  </v-icon>
                </v-col>
                <ul>
                  <li v-for="(item, index) in productList" :key="index">
                    {{ index + 1 }}. {{ item.name }}
                    <v-icon
                      color="primary"
                      small
                      style="cursor: pointer"
                      @click="
                        formModel.custom_fields.productIds.splice(index, 1)
                      "
                    >
                      mdi-delete
                    </v-icon>
                  </li>
                </ul>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="formModel && formModel.product_category_id != 7"
                    v-model="formModel.shipping_quantity"
                    :label="$t('FORM_INPUT_NAMES.shipping_quantity')"
                    :rules="shippingQuantityRules"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-combobox
                v-for="(propertyItem, index) in propertyCollection"
                v-model="formModel.properties[propertyItem.id]"
                return-object
                :key="index"
                multiple
                :items="propertyItem.values"
                :label="propertyItem.name"
                :item-text="translate"
                clearable
                small-chips
                v-on:input="(item) => limiter(item, propertyItem)"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-checkbox
                v-model="formModel.custom_fields.tasteMapEnabled"
                :label="$t('FORM_INPUT_NAMES.taste_map')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-show="formModel.custom_fields.tasteMapEnabled">
            <v-col cols="12" sm="6" md="6">
              <v-slider
                v-model="formModel.custom_fields.tasteMapXAxis"
                min="-100"
                max="100"
                thumb-label="always"
                ticks
              >
                <template v-slot:prepend>
                  <label>{{ $t("FORM_INPUT_NAMES.dry") }}</label>
                </template>
                <template v-slot:append>
                  <label>{{ $t("FORM_INPUT_NAMES.sweet") }}</label>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row v-show="formModel.custom_fields.tasteMapEnabled">
            <v-col cols="12" sm="6" md="6">
              <v-slider
                v-model="formModel.custom_fields.tasteMapYAxis"
                min="-100"
                max="100"
                thumb-label="always"
                ticks
              >
                <template v-slot:prepend>
                  <label>{{ $t("FORM_INPUT_NAMES.full_bodied") }}</label>
                </template>
                <template v-slot:append>
                  <label>{{ $t("FORM_INPUT_NAMES.light") }}</label>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="main_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .main_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text',
                    },
                  ],
                  selectButtonText: $t('FORM_INPUT_NAMES.featured_image'),
                  resolution: '528x704',
                  initialColumnClass:
                    'col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="gallery"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .gallery || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 10,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text',
                    },
                  ],
                  selectButtonText: $t('FORM_INPUT_NAMES.gallery'),
                  // resolution: '528x704',
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="prizes"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .prizes || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 4,
                  inputFields: [
                    {
                      name: 'title',
                      label: 'Neve',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'Díjak',
                  resolution: '128x128',
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="recommendedProductManual"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .recommendedProductManual || ''
                "
                :config="{
                  type: 'combo_box',
                  comboBoxItems: productCollection,
                  itemText: 'name',
                  itemValue: 'id',
                  label: 'Kapcsolódó termékek',
                  multiple: true,
                  maxItemCount: 4,
                  hint: 'Válassz kapcsolódó terméket',
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: 'FORMS.seo_title' }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORMS.seo_description',
                }"
                customFieldName="seoDescription"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8" md="8">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'text',
                      label: 'FORM_INPUT_NAMES.og_site_name',
                    }"
                    customFieldName="og_site_name"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_site_name || ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'text',
                      label: 'FORM_INPUT_NAMES.og_title',
                    }"
                    customFieldName="og_title"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_title || ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <CustomFieldComponent
                    v-if="formModel && formModel.custom_fields"
                    :config="{
                      type: 'multi_select',
                      multiple: false,
                      label: $t('FORM_INPUT_NAMES.og_type'),

                      items: og_types,
                    }"
                    customFieldName="og_type"
                    :value="formModel.custom_fields.og_type || 'website'"
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'textarea',
                      label: 'FORM_INPUT_NAMES.og_description',
                    }"
                    customFieldName="og_description"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_description || ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="og_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.og_image',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Products";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "gallery",
  "main_image",
  "card_image",
  "prizes",
  "recommendedProductManual",

  "seoTitle",
  "seoDescription",
  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
];

export const INITIAL_CUSTOM_FIELDS = {
  isPackage: false,
  productIds: [],
  tasteMapEnabled: false,
  tasteMapXAxis: 0,
  tasteMapYAxis: 0,
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  translations: {},
  product_category_id: null,
  properties: [],
  ean: null,
  sku: null,
  price_lists: [],
  priceNet: null,
  priceGross: null,
  shipping_quantity: 1,

  specialPriceNet: null,
  specialPriceGross: null,
  enable_gmc: 1,

  is_gift: 0,
  gift_start: null,
  gift_end: null,
  gift_from_price: 0,

  // colleagues: [],
  custom_fields: Object.assign({}, INITIAL_CUSTOM_FIELDS),
});

export default {
  name: "PageForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "priceListCollection",
    "publicationCollection",
    "productCollection",
  ],
  components: { CustomFieldComponent },
  // components: { draggable },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,

      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      packageRules: [
        (v) => v == true || v == false || this.$t("ERRORS.field_is_required"),
      ],

      shippingQuantityRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) => (v && v >= 1) || "Minimum 1",
        (v) => (v && v <= 50000) || "maximum 50000",
      ],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },

      previewable_mime_types: [
        "image/png",
        "image/jpeg",
        "image/x-ms-bmp",
        "image/webp",
        "image/gif",
        "image/svg+xml",
      ],
      files: [],
      packageCollection: [
        {
          id: false,
          name: "Normál termék",
        },
        {
          id: true,
          name: "Csomag",
        },
      ],
      selectedProduct: null,
      og_types: ["website", "article"],
    };
  },
  computed: {
    ...mapGetters(["productCategoryCollection", "propertyCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    initialPriceLists() {
      let priceLists = [];

      let priceList = this.priceListCollection.find(
        (item) => item.id == process.env.VUE_APP_PRICE_LIST_ID
      );
      let specialPriceList = this.priceListCollection.find(
        (item) => item.id == process.env.VUE_APP_SPECIAL_PRICE_LIST_ID
      );

      if (priceList)
        priceLists.push({
          price_list_id: priceList.id,
          vat_name: priceList.vat_name,
          vat_value: priceList.vat_value,
          price_net: 0,
          price_gross: 0,
          landing_url: null,
        });

      if (specialPriceList)
        priceLists.push({
          price_list_id: specialPriceList.id,
          vat_name: specialPriceList.vat_name,
          vat_value: specialPriceList.vat_value,
          price_net: 0,
          price_gross: 0,
          landing_url: null,
        });

      return priceLists;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.PRODUCTS");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    productList() {
      if (
        !this.formModel.custom_fields ||
        !this.formModel.custom_fields.productIds
      ) {
        return [];
      }

      return this.formModel.custom_fields.productIds.map((item) =>
        this.productCollection.find((product) => item == product.id)
      );
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        this.selectedProduct = null;
        if (value.editedId) {
          this.loader = true;
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              let modelProperties = [];
              let propertyExist = false;
              this.propertyCollection.forEach((item) => {
                propertyExist = false;

                item.values.forEach((value) => {
                  if (data.properties && data.properties[item.id]) {
                    data.properties[item.id].forEach((property) => {
                      if (
                        value.translations[this.selectedLocale.lang].name ==
                        property.translations[this.selectedLocale.lang].name
                      ) {
                        propertyExist = true;
                      }
                    });
                  }
                });

                if (propertyExist) {
                  modelProperties[item.id] = data.properties[item.id];
                }
              });

              if (!data.custom_fields) {
                data.custom_fields = initialFormData().custom_fields;
              }

              if (!data.custom_fields.isPackage) {
                data.custom_fields.isPackage = false;
                data.custom_fields.productIds = [];
              }

              data.gift_date = [];
              if (data.gift_start) {
                data.gift_date.push(data.gift_start);
              }
              if (data.gift_end) {
                data.gift_date.push(data.gift_end);
              }

              this.formModel = Object.assign({}, data);

              this.formModel.properties = [...modelProperties];
              this.setPrice();
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.formModel.price_lists = this.initialPriceLists;

          this.setPrice();
          this.setTranslatedAttributes();
          //this.formModel.custom_fields = this.initCustomFields;
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchProductCategory", "fetchProperty"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.product_category_id == 7) {
          model.shipping_quantity = 0;
        }
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    setPrice() {
      let priceLists = [];
      let priceList = this.formModel.price_lists.find(
        (item) => item.price_list_id == process.env.VUE_APP_PRICE_LIST_ID
      );
      let specialPriceList = this.formModel.price_lists.find(
        (item) =>
          item.price_list_id == process.env.VUE_APP_SPECIAL_PRICE_LIST_ID
      );

      if (!priceList) {
        priceLists.push(this.initialPriceLists[0]);
      } else {
        priceLists.push(priceList);
      }

      if (!specialPriceList) {
        priceLists.push(this.initialPriceLists[1]);
      } else {
        priceLists.push(specialPriceList);
      }

      this.formModel.price_lists = priceLists;
    },

    handleCalculateBrutto() {
      if (this.formModel.price_lists[0].price_net) {
        this.formModel.price_lists[0].price_gross =
          Number(this.formModel.price_lists[0].price_net) *
          (Number(this.formModel.price_lists[0].vat_value) / 100 + 1);
      }
    },

    handleCalculateNetto() {
      if (this.formModel.price_lists[0].price_gross) {
        this.formModel.price_lists[0].price_net =
          Number(this.formModel.price_lists[0].price_gross) /
          (Number(this.formModel.price_lists[0].vat_value) / 100 + 1);
      }
    },

    handleCalculateSpecialBrutto() {
      if (this.formModel.price_lists[1].price_net) {
        this.formModel.price_lists[1].price_gross =
          Number(this.formModel.price_lists[1].price_net) *
          (Number(this.formModel.price_lists[1].vat_value) / 100 + 1);
      }
    },

    handleCalculateSpecialNetto() {
      if (this.formModel.price_lists[1].price_gross) {
        this.formModel.price_lists[1].price_net =
          Number(this.formModel.price_lists[1].price_gross) /
          (Number(this.formModel.price_lists[1].vat_value) / 100 + 1);
      }
    },

    limiter(item, propertyItem) {
      if (item.find((i) => typeof i === "string")) item.pop();

      if (propertyItem.custom_fields.multiCHK.length == 0 && item.length > 1) {
        item.pop();
      }
    },

    translate(item) {
      if (item.translations[this.selectedLocale.lang]) {
        return item.translations[this.selectedLocale.lang].name;
      }

      return item.translations[0].name;
    },

    handleChangeFormModelCustomFieldValue(property, val) {
      if (val.length > 0) {
        this.$set(this.formModel, "gift_start", val[0]);
      } else {
        this.$set(this.formModel, "gift_start", null);
      }
      if (val.length > 1) {
        this.$set(this.formModel, "gift_end", val[1]);
      } else {
        this.$set(this.formModel, "gift_end", null);
      }
    },
  },

  mounted() {
    this.fetchProductCategory();
    this.fetchProperty();
    this.setTranslatedAttributes();
  },
};
</script>
